import React from "react";
import "./blogSection.css";
import { Divider } from "antd";
import { useNavigate } from "react-router-dom";
import '../../styles/customDivider.css';
const BlogSection = ({texts}) => {
  const navigate = useNavigate();
  return (
    <section className="blog-section">
      <Divider className="custom-divider"/>

      <div className="blog-header">
        <span style={{display:"flex", flexDirection:"column"}}>

        <h3>{texts.blogSection.title}</h3>
        <h2>{texts.blogSection.tagline}</h2>
        </span>
        <button className="discover-button" onClick={() => {
          navigate("/blog");
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        >
            {texts.blogSection.button}
        </button>
      </div>
      <div className="blog-articles">
        {texts.blogSection.articles.map((article, index) => (
          <div className="blog-section-card" key={index}>
            <div className="image-placeholder"></div>
            <div className="blog-info">
              <p className="category">{article.category}</p>
              <h3 className="title">{article.title}</h3>
              <p className="date">{article.date}</p>
            </div>
          </div>
        ))}
      </div>
      <Divider className="custom-divider"/>

    </section>
  );
};

export default BlogSection;
