// import React, { useState } from 'react';
// import './customNavbar.css';
// import { Link, useNavigate } from 'react-router-dom';
// import logo from '../../assets/logo1.png';
// import { GlobalOutlined, MenuOutlined } from '@ant-design/icons';
// import { Drawer } from 'antd';
// import { en, sv } from '../../utils/staticData';
// import LanguageSelector from '../languageSelector/languageSelector';

// const CustomNavBar = ({ language = en, setLanguage }) => {
//   const [drawerVisible, setDrawerVisible] = useState(false);
//   const texts = language;
//   const navigate = useNavigate();

//   const toggleDrawer = () => {
//     setDrawerVisible(!drawerVisible);
//   };

//   return (
//     <nav className="custom-nav">
//       <Link to="/">
//         <div className="logo">
//           <img src={logo} alt="Logo" />
//         </div>
//       </Link>
//       <div className="nav-hamburger">
//         <MenuOutlined onClick={toggleDrawer} className="hamburger-icon" />
//       </div>

//       <Drawer
//         title={<div className="logo"><img src={logo} alt="Logo" /></div>}
//         placement="right"
//         onClose={toggleDrawer}
//         visible={drawerVisible}
//         className="nav-drawer"
//         drawerStyle={{ backgroundColor: '#1A1A2E' }} /* Set background color for drawer */
//       >
//         <ul className="nav-items">
//           <li><Link to="/services" onClick={toggleDrawer}>{texts.header.services}</Link></li>
//           <li><Link to="/about" onClick={toggleDrawer}>{texts.header.aboutUs}</Link></li>
//           <li><Link to="/blog" onClick={toggleDrawer}>{texts.header.blog}</Link></li>
//         </ul>
//         <div className="nav-right">
//           <LanguageSelector language={language} setLanguage={setLanguage} />
//           <button className="contact-button" onClick={() => { toggleDrawer(); navigate("/contact"); }}>
//             {texts.header.contactUs}
//           </button>
//         </div>
//       </Drawer>
//     </nav>
//   );
// };

// export default CustomNavBar;



import React, { useState } from 'react';
import './customNavbar.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo1.png';
import hoverLogo from '../../assets/logo1_v2.png';
import { GlobalOutlined, MenuOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { en, sv } from '../../utils/staticData';
import LanguageSelector from '../languageSelector/languageSelector';
import { useMediaQuery } from 'react-responsive';

const CustomNavBar = ({ language = en, setLanguage }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const texts = language;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 }); // Media query for smaller screens

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <nav className="custom-nav">
      {/* <Link to="/">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
      </Link> */}
      <Link to="/">
        <div className="logo">
          <img src={logo} alt="Logo" className="logo-image" />
          <img src={hoverLogo} alt="Hover Logo" className="hover-logo-image" />
        </div>
      </Link>


      {isMobile ? (
        <>
          {/* Hamburger icon for mobile view */}
          <div className="nav-hamburger">
            <MenuOutlined onClick={toggleDrawer} className="hamburger-icon" />
          </div>

          {/* Drawer for mobile view */}
          <Drawer
            title={<Link to="/"><div className="logo">
                  <img src={logo} alt="Logo" className="logo-image" />
                  <img src={hoverLogo} alt="Hover Logo" className="hover-logo-image" />
              </div></Link>}
            placement="right"
            onClose={toggleDrawer}
            open={drawerVisible}
            className="nav-drawer"
            drawerStyle={{ backgroundColor: '#1A1A2E' }}
          >
            <ul className="nav-items">
              <li><Link to="/services" onClick={toggleDrawer}>{texts.header.services}</Link></li>
              <li><Link to="/about" onClick={toggleDrawer}>{texts.header.aboutUs}</Link></li>
              <li><Link to="/blog" onClick={toggleDrawer}>{texts.header.blog}</Link></li>
            </ul>
            <div className="nav-right">
              <LanguageSelector language={language} setLanguage={setLanguage} />
              <button className="contact-button" onClick={() => { toggleDrawer(); navigate("/contact"); }}>
                {texts.header.contactUs}
              </button>
            </div>
          </Drawer>
        </>
      ) : (
        <>
          {/* Standard navigation for desktop view */}
          <ul className="nav-items">
            <li><Link to="/services">{texts.header.services}</Link></li>
            <li><Link to="/about">{texts.header.aboutUs}</Link></li>
            <li><Link to="/blog">{texts.header.blog}</Link></li>
          </ul>
          <div className="nav-right">
            <LanguageSelector language={language} setLanguage={setLanguage} />
            <button className="contact-button" onClick={() => navigate("/contact")}>{texts.header.contactUs}</button>
          </div>
        </>
      )}
    </nav>
  );
};

export default CustomNavBar;
