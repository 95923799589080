import './services.css';
import '../../components/promotionComponent/promotionSection.css';
import React from 'react';
import { en, sv } from '../../utils/staticData';
import { useSpring, animated } from '@react-spring/web';
import  logo2  from '../../assets/logo2.png';
import aboutUsSection from '../../assets/stock1.png';
import pricingSectionImageOne from '../../assets/stock2.png';
import pricingSectionImageTwo from '../../assets/stock3.png';
import { Button, Collapse, Divider } from 'antd';
import FaqSection from '../../components/faqComponent/faqSection';
import { CaretRightOutlined } from '@ant-design/icons';
import BlogSection from '../../components/blogsComponent/blogSection';
import ContactForm from '../../components/contactFormComponent/contactForm';
import PromotionSection from '../../components/promotionComponent/promotionSection';
import AboutUs from '../../components/aboutUsComponent/aboutUs';
import OurStory from '../../components/ourStoryComponent/ourStory';
import FaqForAboutPage from '../../components/faqComponent/faqForAboutPage';
import ServicesComponent from '../../components/servicesComponent/servicesComponent';
import WorkingSection from '../../components/workingSection/workingSection';
import PricingSection from '../../components/pricingSection/pricingSection';

export default function Services({ language = en }) {
  console.log("language", language);
  
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 500 });

  const texts = language;
  return (
    <div className='services-page'>
       <animated.section className="hero" style={props}>
        <h1>{texts.servicesPage.title}</h1>
        <p>{texts.servicesPage.tagline}</p>
        <img src={logo2} alt="Logo2" className="rotating-image" />
      </animated.section>
     
      <ServicesComponent texts={texts}/>


    
        <WorkingSection texts={texts} />


      
      <PricingSection texts={texts} isLarge={true} />

        <div style={{position:"relative", bottom:"290px", right:"0px", left:"0px"}}>

         <PromotionSection texts={texts} />
        </div>
     

    </div>
  )
}
