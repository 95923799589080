import React from 'react';
import { Button } from 'antd';
import './promotionSection.css';
import { useNavigate } from 'react-router-dom';

const PromotionSection = ({texts}) => {
  const navigate = useNavigate();
  return (
    <div style={{ background:"transparent", padding: "40px"}}>

    <div className="hero-section">
      <div className="hero-content">
        <h1 className="hero-title">
          {texts.promotionSection.title.line1}<br />
          {texts.promotionSection.title.line2} <br />
          {texts.promotionSection.title.line3}
        </h1>
        <p className="hero-subtitle">
          {texts.promotionSection.tagline}
        </p>
        <Button className="hero-button" type="primary" 
          onClick={() => {
            const aboutUs = document.getElementById('about-us');
            if (aboutUs) {
              aboutUs.scrollIntoView({ behavior: 'smooth' });
            } else {
              navigate('/about');
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }} 
          >
          {texts.promotionSection.button}
        </Button>
      </div>
      <div className="hero-image">
        <div className="logo-background"></div>
      </div>
    </div>
    </div>
  );
};

export default PromotionSection;
