import './home.css';
import React from 'react';
import { en, sv } from '../../utils/staticData';
import { useSpring, animated } from '@react-spring/web';
import  logo2  from '../../assets/logo2.png';
import aboutUsSection from '../../assets/stock1.png';
import pricingSectionImageOne from '../../assets/stock2.png';
import pricingSectionImageTwo from '../../assets/stock3.png';
import { Collapse, Divider } from 'antd';
import FaqSection from '../../components/faqComponent/faqSection';
import { CaretRightOutlined } from '@ant-design/icons';
import BlogSection from '../../components/blogsComponent/blogSection';
import ContactForm from '../../components/contactFormComponent/contactForm';
import PromotionSection from '../../components/promotionComponent/promotionSection';
import WorkingSection from '../../components/workingSection/workingSection';
import PricingSection from '../../components/pricingSection/pricingSection';

const { Panel } = Collapse;

export default function Home({ language = en }) {
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 500 });

  const texts = language;

  return (
    <div className="homepage">
      <animated.section className="home-hero" style={props}>
        <h3>{texts.hero.tagline}</h3>
        <h1>{texts.hero.title}</h1>
        <p>{texts.hero.paragraph}</p>
        <button onClick={() => document.getElementById('working-section').scrollIntoView({ behavior: 'smooth' })} className="primary-button">
          {texts.hero.button}
        </button> 

        <span onClick={() => document.getElementById('contact-form').scrollIntoView({ behavior: 'smooth' })} style={{cursor:"pointer"}}>{texts.hero.linkButton}</span>
        <img src={logo2} alt="Logo2" className="home-rotating-image" />
      </animated.section>

    
      <section id='about-us' className="home-about-us">
        <div className='home-about-us-section'>
          <span>

          <h3>{texts.aboutUs.title}</h3>
          <h2>{texts.aboutUs.tagline}</h2>
          <h4>{texts.aboutUs.semiTagline}</h4>
          <p>{texts.aboutUs.paragraph}</p>
          <h5>{texts.aboutUs.linkButton}</h5>
          </span>
          <img src={aboutUsSection} alt="About Us" />

        </div>

     

        <WorkingSection id="working-section" texts={texts} />

        

        <div className='home-services-section'>
          <span>
          <h3>{texts.services.title}</h3>
          <h2>{texts.services.tagline}</h2>
          </span>
          <div className="home-services-section-services">
            {
              texts.services.services.map((service, index) => (
                <div key={index}>
                  <div className="icon-container">
                    {service.icon}
                  </div>
                  <h2>{service.title}</h2>
                  <p>{service.description}</p>
                  <h5 style={service.linkButton === "Learn More" ? {width:"90px"} : service.linkButton === "Discover More" ? {width:"112px"} : {width:"103px"}}>{service.linkButton}</h5>
                </div>
              ))
            }
          </div>
        </div>
      </section>


     

      <PricingSection texts={texts} />
      <FaqSection texts={texts}/>

        <BlogSection texts={texts}/>

        <ContactForm id='contact-form' texts={texts}/>
        
        <PromotionSection texts={texts}/>

    </div>
  );
}
