import React from 'react';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import './faqForAboutPage.css';

const { Panel } = Collapse;

const FaqForAboutPage = ({ texts }) => {
  return (
    <section className="faq-about">
      <div className="our-story-section">

      <span className="faq-about-texts">
        <h3>{texts?.aboutUsPage?.faqComponent?.title}</h3>
        <h2>{texts?.aboutUsPage?.faqComponent?.tagline}</h2>
        <p>{texts?.aboutUsPage?.faqComponent?.description}</p>
      </span>
      <Collapse
        accordion
        bordered={false}
        expandIcon={({ isActive }) => 
          <div className={`custom-icon-container ${isActive ? 'active' : ''}`}>
            <CaretRightOutlined className="custom-icon" />
          </div>
        }
        className="custom-about-collapse"
        expandIconPosition='end'
      >
        {texts?.aboutUsPage?.faqComponent?.questions?.map((question, index) => (
          <Panel header={question?.title} key={index} className="custom-about-panel">
            <p>{question?.description}</p>
          </Panel>
        ))}
      </Collapse>
      </div>

    </section>
  );
};

export default FaqForAboutPage;
