import React from 'react';
import './ourStory.css';

const OurStory = ({  texts }) => {
  return (
    <section className="our-story">
      <div className="our-story-section">
        <div className="our-story-text">
          <h3>{texts.aboutUsPage.ourStoryComponent.title}</h3>
          <h2>{texts.aboutUsPage.ourStoryComponent.tagline}</h2>
        </div>
        <div className='our-story-paragraph'>
            <p>{texts.aboutUsPage.ourStoryComponent.paragraph}</p>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
