export const theme = {
    colors: {
      primary: '#1B1C3B', // Dark blue
      secondary: '#EBF7FE', // Light blue
      accent: '#E84038', // Red
    },
    typography: {
      h1: {
        fontFamily: 'All Round Gothic, sans-serif',
        fontSize: '72px',
        color: '#EBF7FE',
        mobileSize: '45px',
      },
      h2: {
        fontFamily: 'All Round Gothic, sans-serif',
        fontSize: '56px',
        color: '#1B1C3B',
        mobileSize: '40px',
      },
      // Continue for other heading and paragraph styles
    },
  };