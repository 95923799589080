import './about.css';
import '../../components/promotionComponent/promotionSection.css';
import React from 'react';
import { en, sv } from '../../utils/staticData';
import { useSpring, animated } from '@react-spring/web';
import  logo2  from '../../assets/logo2.png';
import aboutUsSection from '../../assets/stock1.png';
import pricingSectionImageOne from '../../assets/stock2.png';
import pricingSectionImageTwo from '../../assets/stock3.png';
import { Button, Collapse, Divider } from 'antd';
import FaqSection from '../../components/faqComponent/faqSection';
import { CaretRightOutlined } from '@ant-design/icons';
import BlogSection from '../../components/blogsComponent/blogSection';
import ContactForm from '../../components/contactFormComponent/contactForm';
import PromotionSection from '../../components/promotionComponent/promotionSection';
import AboutUs from '../../components/aboutUsComponent/aboutUs';
import OurStory from '../../components/ourStoryComponent/ourStory';
import FaqForAboutPage from '../../components/faqComponent/faqForAboutPage';

export default function About({ language = en }) {
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 500 });

  const texts = language;
  return (
    <div className='about-us-page'>
       <animated.section className="hero" style={props}>
        <h1>{texts.aboutUs.title}</h1>
        <p>{texts.contact.tagline}</p>
        <img src={logo2} alt="Logo2" className="rotating-image" />
      </animated.section>
     
      <AboutUs texts={texts}/>

      <OurStory texts={texts} />
      <FaqForAboutPage texts={texts} />
      <PromotionSection texts={texts} />
     

    </div>
  )
}
