import { Divider } from 'antd'
import React from 'react'
import './servicesComponent.css'

export default function ServicesComponent({  texts }) {
  return (
      <section className="services">
      <div className="services-section">
        <div className="services-text">
          <h3>{texts.servicesPage.servicesComponent.title}</h3>
          <h2>{texts.servicesPage.servicesComponent.tagline}</h2>
        </div>
        <div className='services-paragraph'>
            <p>{texts.servicesPage.servicesComponent.paragraph}</p>
        </div>
      </div>

      {
        texts.servicesPage.servicesComponent.services.map((service, index) => (
          <div key={index}>
            <Divider className="services-divider" />
            <div className='service-block'>

              <div className='service-content'>
                <div className='service-header-container'>
                  <div className="icon-title-container">
                    <div className="icon-container">
                      {service.icon}
                    </div>
                    <h2>{service.title}</h2>
                  </div>
                </div>

                <h4>{service.tagline}</h4>
                <p>{service.description}</p>
              </div>
            

              <div className='service-card-content'>
                <ul>
                  {service.detailsCard.map((card, index) => (
                    <li key={index} className='service-card'>
                      <h2>{card.title}</h2>
                    </li>
                  ))}
                </ul>
                <button>{service.linkButton}</button>
              </div>

            </div>
          </div>
        ))
      }
    </section>
  )
}
