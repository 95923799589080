import React from "react";
import { Divider, Button, Input } from "antd";
import "./blogComponent.css";

const BlogComponentForblogsPage = ({ texts, onOpenModal }) => {
  const articles = texts?.blogsPage?.blogComponent?.cards;

  const topArticles = articles?.slice(0, 2); // Latest two articles
  const gridArticles = articles?.slice(2, 8); // Next six articles for the grid

  return (
    <section className="blog">
      {/* Top Section with Two Latest Blogs */}
      <div className="top-articles">
        {topArticles.map((article) => (
          <div className="top-article-card" key={article.id} onClick={() => onOpenModal(article)}>
            <div className="thumbnail" style={{ backgroundImage: `url(${article.thumbnail})` }}></div>
            <div className="article-info">
              <h3 className="category">{article.category}</h3>
              <h2 className="title">{article.title}</h2>
              <p className="date">{article.date}</p>
            </div>
          </div>
        ))}
      </div>

      <Divider className="blog-divider" />

      {/* Grid of Blog Cards */}
      <div className="blog-grid">
        {gridArticles.map((article) => (
          <div className="blog-card" key={article.id} onClick={() => onOpenModal(article)}>
            <div className="blog-card-thumbnail" style={{ backgroundImage: `url(${article.thumbnail})` }}></div>
            <div className="article-info">
              <h3 className="blog-card-category">{article.category}</h3>
              <h2 className="blog-card-title">{article.title}</h2>
              <p className="blog-card-date">{article.date}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Load More Button */}
      <div className="load-more-container">
        <Button type="primary" className="load-more-button" size="large">
          {texts?.blogsPage?.blogComponent?.loadMoreButton}
        </Button>
      </div>
      <Divider className="blog-divider" />

      {/* Newsletter Section */}
      <div className="newsletter">
        <h2>
          {texts?.blogsPage?.blogComponent?.newsletter}
        </h2>
        <span>

        <Input placeholder={texts?.blogsPage?.blogComponent?.emailPlaceholder}
         className="email-input" />
        <Button type="primary" className="subscribe-button">
          {texts?.blogsPage?.blogComponent?.subscribeButton}
        </Button>
        </span>
      </div>

      <Divider className="blog-divider" />
    </section>
  );
};

export default BlogComponentForblogsPage;
