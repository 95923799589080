import React from 'react';
import { Divider, Layout, Menu } from 'antd';
import { en, navItems } from '../../utils/staticData';
import './customLayout.css';
import '../../styles/customDivider.css';
import CustomNavbar from '../customNavbar/customNavbar';
import { Link } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

export default function CustomLayout({ page, language = en,setLanguage }) {
  const texts = language;

  return (
    <Layout className="custom-layout">
      <CustomNavbar language={language} setLanguage={setLanguage} />
      <Content className="custom-content">
        {page}
      </Content>
      <Footer className="custom-footer">
        <div className="footer-content">
        <Link to="/" onClick={() => window.scrollTo(0, 0)}><div className="footer-logo">{texts.footer.companyName}</div></Link>
          <div className="footer-links">
            <div className="footer-title">{texts.footer.menu.title}</div>
            <Link to="/about" onClick={() => window.scrollTo(0, 0)}><div>{texts.footer.menu.about}</div></Link>
            <Link to="/services" onClick={() => window.scrollTo(0, 0)}><div>{texts.footer.menu.service}</div></Link>
            <Link to="/blog" onClick={() => window.scrollTo(0, 0)}><div>{texts.footer.menu.blog}</div></Link>
            <Link to="/contact" onClick={() => window.scrollTo(0, 0)}><div>{texts.footer.menu.contact}</div></Link>
          </div>
          <div className="footer-links">
          <Link to="/services" onClick={() => window.scrollTo(0, 0)}><div className="footer-title">{texts.footer.service.title}</div></Link>
          <Link to="/services" onClick={() => window.scrollTo(0, 800)}><div>{texts.footer.service.webDevelopment}</div></Link>
          <Link to="/services" onClick={() => window.scrollTo(0, 1600)}><div>{texts.footer.service.digitalStrategy}</div></Link>
          <Link to="/services" onClick={() => window.scrollTo(0, 2400)}><div>{texts.footer.service.softwareSolutions}</div></Link>
          </div>
        </div>
        <div className="footer-bottom">
          <Divider className='custom-divider'/>
          <div className="footer-bottom-container">
            <p>{texts.footer.bottom.copyRight}</p>
            <div className="footer-bottom-links">
              <span>{texts.footer.bottom.termsOfUse}</span>
              <span>{texts.footer.bottom.privacyPolicy}</span>
            </div>
          </div>
        </div>
      </Footer>


    </Layout>
  );
}
