import React from 'react';
import './pricingSection.css'; // Ensure you import the corresponding CSS file
import  logo2  from '../../assets/logo2.png';
import pricingSectionImageOne from '../../assets/stock2.png';
import pricingSectionImageTwo from '../../assets/stock3.png';
import { useNavigate } from 'react-router-dom';

export default function PricingSection ({ texts, isLarge}) {
  const navigate = useNavigate();
  return (
    <section className={`pricing ${isLarge ? 'large' : ''}`}>
      <div className="pricing-item">
        <span>
          <h3>{texts.pricing.title}</h3>
          <h2>{texts.pricing.tagline[0]}</h2>
          <p>{texts.pricing.description[0]}</p>
        </span>
        <img src={pricingSectionImageOne} alt="Pricing Section Image One" />
      </div>

      <div className="pricing-item">
        <img src={pricingSectionImageTwo} alt="Pricing Section Image Two" />
        <span>
          <h2>{texts.pricing.tagline[1]}</h2>
          <p>{texts.pricing.description[1]}</p>
        </span>
      </div>

      <div className='pricing-sub'>
        <span>
          <h3>{texts.pricing.title}</h3>
          <h2>{texts.pricing.tagline[2]}</h2>
          <button onClick={() => {
            const contactForm = document.getElementById('contact-form');
            if (contactForm) {
              contactForm.scrollIntoView({ behavior: 'smooth' });
            } else {
              navigate('/contact');
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }} 
          
          >{texts.pricing.linkButton}</button>
        </span>
        <img src={logo2} alt="Pricing Section Image Three" />
      </div>
    </section>
  );
};


