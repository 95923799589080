import './contact.css';
import React from 'react';
import { en, sv } from '../../utils/staticData';
import { useSpring, animated } from '@react-spring/web';
import  logo2  from '../../assets/logo2.png';
import aboutUsSection from '../../assets/stock1.png';
import pricingSectionImageOne from '../../assets/stock2.png';
import pricingSectionImageTwo from '../../assets/stock3.png';
import { Collapse, Divider } from 'antd';
import FaqSection from '../../components/faqComponent/faqSection';
import { CaretRightOutlined } from '@ant-design/icons';
import BlogSection from '../../components/blogsComponent/blogSection';
import ContactForm from '../../components/contactFormComponent/contactForm';
import PromotionSection from '../../components/promotionComponent/promotionSection';

const { Panel } = Collapse;
export default function Contact({ language = en }) {
  
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 500 });

  const texts = language;

  return (
    <div className='contact-page'>
       <animated.section className="hero" style={props}>
        <h1>{texts.contact.title}</h1>
        <p>{texts.contact.tagline}</p>
        <img src={logo2} alt="Logo2" className="rotating-image" />
      </animated.section>
     
      <ContactForm texts={texts}/>


      <FaqSection texts={texts} />

    </div>

  )
}
