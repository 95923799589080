import React from 'react';
import './aboutUs.css';

const AboutUs = ({  texts }) => {
  return (
    <section className="about-us">
      <div className="about-us-section">
        <div className="about-us-text">
          <h3>{texts.aboutUsPage.aboutUsComponent.title}</h3>
          <h2>{texts.aboutUsPage.aboutUsComponent.tagline}</h2>
        </div>
        <div className='about-us-paragraph'>
            <p>{texts.aboutUsPage.aboutUsComponent.paragraph}</p>
        </div>
      </div>
        <div className="about-us-cards">
          {texts.aboutUsPage.aboutUsComponent.cards.map((card, index) => (
            <div key={index} className="card">
              {/* Assuming each card is just an image; replace as necessary */}
              <img src={card.img} alt={`Card ${index + 1}`} />
            </div>
          ))}
        </div>
    </section>
  );
};

export default AboutUs;
