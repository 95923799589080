import { Modal } from 'antd';
import React from 'react';
// import './modal.css';
import styled, { css } from "styled-components";

export default function CustomModal({ detailsModal, handleCloseModal, selectedBlog }) {
  const title = selectedBlog && (
    <div className="modal-header">
      <h2 className="modal-title">{selectedBlog?.title}</h2>
      <div className="modal-meta">
        <p className="modal-category">{selectedBlog.category}</p>
        <p className="modal-date">{selectedBlog.date}</p>
      </div>
    </div>
  );

  return (
    <CustomModalStyle
      title={title}
      width="1000px"
      heigth="1000px"
      open={detailsModal}
      onCancel={handleCloseModal}
      footer={null}
      maskClosable={false}
      
    >
      {selectedBlog && (
        <div className="modal-content-wrapper">
          <p className="modal-content">{selectedBlog.content}</p>
        </div>
      )}
    </CustomModalStyle>
  );
}

export const CustomModalStyle = styled(Modal)`
  .ant-modal {
    max-width: 90vw; /* Use viewport width for responsive max width */
    width: 80vw; /* Adjust width based on viewport width */
    height: 70vh; /* Use viewport height for responsive height */
    overflow: hidden; /* Hide overflow on the modal itself */
  }

  .ant-modal-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto; /* Ensure body is scrollable if content overflows */
    max-height: 60vh; /* Adjust based on your needs */
    
    /* Custom scrollbar styling */
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1A1A2E;
      border-radius: 2px;
      min-height: 30px; /* Ensure the thumb isn't too small */
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 40px;
    width: 100%;
  }
  
  .modal-title {
    font-size: 1.5rem; /* Responsive font size */
    font-weight: bold;
    margin: 0;
    color: #21253b;
  }
  
  .modal-meta {
    text-align: right;
    width: fit-content;
    font-size: 0.875rem; /* Responsive font size */
    color: #6c757d;
  }
  
  .modal-category,
  .modal-date {
    margin: 0;
  }
  
  .modal-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .modal-thumbnail {
    width: 100%;
    height: 200px; /* Adjust based on content */
    background-size: cover;
    background-position: center;
    border-radius: 8px;
  }
  
  .modal-content {
    font-size: 1rem; /* Responsive font size */
    line-height: 1.6;
    color: #4a4a4a;
    text-align: justify;
    padding: 0 10px;
  }
  
  @media (max-width: 768px) {
    .ant-modal {
      width: 90vw; /* Adjust width for smaller screens */
      height: auto; /* Allow height to adjust based on content */
    }
    
    .modal-title {
      font-size: 1.25rem; /* Adjust font size for smaller screens */
    }
    
    .modal-meta {
      font-size: 0.75rem; /* Adjust font size for smaller screens */
    }
    
    .modal-content {
      font-size: 0.875rem; /* Adjust font size for smaller screens */
    }
  }
`;