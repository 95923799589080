import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GlobalStyle } from './styles/globalStyle';
import { theme } from './styles/theme';
import CustomLayout from './components/layout/customLayout';
import Home from './pages/homepage/index';
import About from './pages/about/index';
import Contact from './pages/contact/index';
import Blog from './pages/blog/index';
import { en } from './utils/staticData';
import Services from './pages/services';

function App() {
  const [language, setLanguage] = useState(en);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route index element={<CustomLayout page={<Home language={language} />} language={language} setLanguage={setLanguage} />} />
          <Route path="/about" element={<CustomLayout page={<About language={language} />} language={language} setLanguage={setLanguage} />} />
          <Route path="/contact" element={<CustomLayout page={<Contact language={language} />} language={language} setLanguage={setLanguage} />} />
          <Route path="/blog" element={<CustomLayout page={<Blog language={language} />} language={language} setLanguage={setLanguage} />} />
          <Route path="/services" element={<CustomLayout page={<Services language={language} />} language={language} setLanguage={setLanguage} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
