import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.secondary};
  }

  h1, h2, h3, h4, h5, p {
    margin: 0;
  }

  h1 {
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    font-size: ${({ theme }) => theme.typography.h1.fontSize};
    color: ${({ theme }) => theme.typography.h1.color};
    
    @media (max-width: 700px) {
      font-size: ${({ theme }) => theme.typography.h1.mobileSize};
    }
  }

  h2 {
    font-family: ${({ theme }) => theme.typography.h2.fontFamily};
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    color: ${({ theme }) => theme.typography.h2.color};
    
    @media (max-width: 700px) {
      font-size: ${({ theme }) => theme.typography.h2.mobileSize};
    }
  }

  /* Add styles for h3, h4, p, etc. */
`;