import { GlobalOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import { en, sv } from '../../utils/staticData';
import './languageSelector.css';

const LanguageSelector = ({setLanguage}) => {

  const handleMenuClick = ({ key }) => {
    key === 'english' ? setLanguage(en) : setLanguage(sv);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="english">English</Menu.Item>
      <Menu.Item key="swedish">Swedish</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <GlobalOutlined className="language-button" />
    </Dropdown>
  );
};

export default LanguageSelector;
