import React from 'react';
import { Input, Button, Select } from 'antd';
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';
import './contactForm.css';

const { TextArea } = Input;
const { Option } = Select;

const ContactForm = ({texts, id}) => {
  return (
    <div id='contact-form' style={{background: "#F0F4F8", padding:"50px"}}>
    
    <h1 className="contact-us-title">{texts.contact.contactForm.title}</h1>
    <div className="contact-us-container">
      <div className="contact-info">
        <div className="contact-info-item">
          <PhoneOutlined className="contact-icon" />
          <div>
            <div className="contact-title">{texts.contact.contactForm.phone}</div>
            <div className="contact-detail">{texts.contact.contactForm.phoneDetail}</div>
          </div>
        </div>
        <div className="contact-info-item">
          <MailOutlined className="contact-icon" />
          <div>
            <div className="contact-title">{texts.contact.contactForm.email}</div>
            <div className="contact-detail">{texts.contact.contactForm.emailDetail}</div>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <h2 className="form-title">{texts.contact.contactForm.formTitle}</h2>
        <Input placeholder={texts.contact.contactForm.emailPlaceholder} className="form-input" />
        <Select defaultValue={texts.contact.contactForm.subjectPlaceholder} className="form-select">
          <Option value="General Inquiry">{texts.contact.contactForm.generalInquiry}</Option>
          <Option value="Support">{texts.contact.contactForm.support}</Option>
          <Option value="Feedback">{texts.contact.contactForm.feedback}</Option>
        </Select>
        <TextArea placeholder={texts.contact.contactForm.messagePlaceholder} rows={4} className="form-textarea" />
        <Button type="primary" htmlType="submit" className="submit-button">
        {texts.contact.contactForm.submitButton}
        </Button>
      </div>
    </div>
    </div>
  );
};

export default ContactForm;
