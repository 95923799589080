import React from 'react';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import './faqSection.css';
import { useNavigate } from 'react-router-dom';

const { Panel } = Collapse;

const FaqSection = ({ texts }) => {
  const navigate = useNavigate();
  return (
    <section className="faq">
      <span className="faq-texts">
        <h3>{texts?.faq?.title}</h3>
        <h2>{texts?.faq?.tagline}</h2>
        <p>{texts?.faq?.description}</p>
        <p  onClick={() => {
            const contactForm = document.getElementById('contact-form');
            if (contactForm) {
              contactForm.scrollIntoView({ behavior: 'smooth' });
            } else {
              navigate('/contact');
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }} className="faq-link">{texts?.faq?.linkButton}</p>
      </span>
      <Collapse
        accordion
        bordered={false}
        expandIcon={({ isActive }) => 
          <div className={`custom-icon-container ${isActive ? 'active' : ''}`}>
            <CaretRightOutlined className="custom-icon" />
          </div>
        }
        className="custom-collapse"
        expandIconPosition='end'
      >
        {texts?.faq?.questions?.map((question, index) => (
          <Panel header={question?.title} key={index} className="custom-panel">
            <p>{question?.description}</p>
          </Panel>
        ))}
      </Collapse>
    </section>
  );
};

export default FaqSection;
