// WorkingSection.js
import React from 'react';
import './workingSection.css'; // Import your CSS file here
import  logo2  from '../../assets/logo2.png';
export default function WorkingSection ({ texts, id }) {
  return (
    <div id='working-section' className='working-section'>
      <span>
        <h3>{texts.working.title}</h3>
        <h2>{texts.working.tagline}</h2>
        <p>{texts.working.paragraph}</p>
        {/* <h5>{texts.working.linkButton}</h5> */}
      </span>
      <div className="working-section-steps">
        {texts.working.steps.map((step, index) => (
          <div key={index} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
        <img src={logo2} alt="Logo2" className="working-section-image" />
      </div>
    </div>
  );
};