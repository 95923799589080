import './blog.css';
import { Modal } from 'antd'; 
import '../../components/promotionComponent/promotionSection.css';
import React, { useState } from 'react';
import { en, sv } from '../../utils/staticData';
import { useSpring, animated } from '@react-spring/web';
import  logo2  from '../../assets/logo2.png';
import aboutUsSection from '../../assets/stock1.png';
import pricingSectionImageOne from '../../assets/stock2.png';
import pricingSectionImageTwo from '../../assets/stock3.png';
import { Button, Collapse, Divider } from 'antd';
import FaqSection from '../../components/faqComponent/faqSection';
import { CaretRightOutlined } from '@ant-design/icons';
import BlogSection from '../../components/blogsComponent/blogSection';
import ContactForm from '../../components/contactFormComponent/contactForm';
import PromotionSection from '../../components/promotionComponent/promotionSection';
import AboutUs from '../../components/aboutUsComponent/aboutUs';
import OurStory from '../../components/ourStoryComponent/ourStory';
import FaqForAboutPage from '../../components/faqComponent/faqForAboutPage';
import BlogComponentForBlogPage from '../../components/blogsComponent/blogComponentForBlogPage';
import CustomModal from '../../components/modalComponent/customModal';

export default function Blog({ language = en }) {
  const [detailsModal, setDetailsModal] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 500 });

  const texts = language;

  const handleOpenModal = (article) => {
    setSelectedBlog(article); // Set the selected blog
    setDetailsModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setDetailsModal(false); // Hide the modal
    setSelectedBlog(null); // Clear the selected blog
  };

  return (
    <div className='blog-page'>
       <animated.section className="hero" style={props}>
        <h1>{texts?.blogsPage?.title}</h1>
        <p>{texts?.blogsPage?.tagline}</p>
        <img src={logo2} alt="Logo2" className="rotating-image" />
      </animated.section>
     
      <BlogComponentForBlogPage texts={texts} onOpenModal={handleOpenModal} />  
      
      <PromotionSection texts={texts} />

      <CustomModal detailsModal={detailsModal} handleCloseModal={handleCloseModal} selectedBlog={selectedBlog} />
     

    </div>
  )
}
